import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import ElementUI from 'element-ui'
import VueAnimateNumber from 'vue-animate-number'
import Vant from 'vant';
import 'vant/lib/index.less';
import 'element-ui/lib/theme-chalk/index.css'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import "./style/animate.min.css";


Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueAnimateNumber)
Vue.use(Vant)
Vue.use(preview)


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
