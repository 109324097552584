/**
 * 路由文件
 */
import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "home",
        meta:{
            title:'首页'
        },
        component: () => import("@/views/home"),
    },
    {
        path: "/leaveBoard",
        name: "leaveBoard",
        meta:{
            title:'留言板'
        },
        component: () => import("@/views/leaveBoard"),
    },
    {
        path: "/addLeave",
        name: "addLeave",
        meta:{
            title:'添加留言'
        },
        component: () => import("@/views/addLeave"),
    },
    {
        path: "/timeLine",
        name: "timeLine",
        meta:{
            title:'事件'
        },
        component: () => import("@/views/timeLine"),
    }
]

const router = new VueRouter({
    routes,
})

// 路由导航守卫
router.beforeEach((to, from, next) => {
    next()
})

export default router
