<template>
  <div id="app">
    <router-view></router-view>
    <musicView />
  </div>
</template>

<script>
import musicView from "./components/musicView"
export default {
  name: 'App',
  components: {
    musicView
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  color: #222222;
  font-size: 16px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  margin: 0 auto;
  padding: 0;
  max-width: 677px;
  overflow: hidden;
}

.fbtn1 {
  background-color: #e7c275;
}

.fbtn2 {
  background-color: #e7c275;
}

.fbtn3 {
  background-color: #9a7b3a;
}

.cp {
  cursor: pointer;
}
</style>
